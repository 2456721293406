.team-container {
  width: 100%;
  height: calc(100% - 10px);
  margin: 3px 0 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 500px) {
  .team-container {
    height: auto;
  }
}

.team-div {
  border: 2px solid #3051ff;
  border-radius: 50px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
  text-align: center;
}

.team-joined-div {
  width: 100%;
  height: 100%;
  padding: 0 3%;
}

.team-members-head-div .gradient-head {
  margin: 0;
}

.remove-btn {
  color: rgb(148, 148, 148);
}

.remove-btn:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.team-primary-btn {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  border-radius: 15px;
  background: linear-gradient(91.89deg, #6f57ff -5.11%, #25d8ff 152.73%);
  color: white;
  height: 55px;
  width: 300px;
  font-weight: 600;
  font-size: 1.2rem;
}

.team-primary-btn:hover {
  filter: drop-shadow(0 0 10px #6f57ff);
}

.team-secondary-btn {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  border-radius: 15px;
  border: 2px solid #3051ff;
  background: transparent;
  color: white;
  height: 55px;
  width: 300px;
  font-weight: 600;
  font-size: 1.2rem;
}

.team-secondary-btn:hover {
  filter: drop-shadow(0 0 10px #3051ff);
}

@media screen and (max-width: 600px) {
  .team-primary-btn, .team-secondary-btn {
    width: 200px;
  }
  .remove-btn {
    font-size: 0.8rem;
  }
}

.gradient-head {
  background: linear-gradient(98.49deg, #6f57ff, #25d8ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0;
}

.team-name {
  font-size: 1.7rem;
  margin-top: 0 !important;
}

.team-status {
  font-size: 1.5rem;
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-status.team-member {
  margin-bottom: 5px;
}

.team-action-div {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  margin-top: 40px;
}

@media screen and (max-width: 600px) {
  .member-name-grid {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 490px) {
  .team-name {
    font-size: 1.5rem;
    margin-top: 0 !important;
  }
  .gradient-head {
    font-size: 1.5rem;
  }
  .team-status {
    font-size: 1.2rem;
  }
  .team-action-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .team-action-div .team-primary-btn {
    margin-bottom: 10px;
    font-size: 1rem;
    width: 90% !important;
    height: 45px;
  }
  .team-action-div .team-secondary-btn {
    margin-bottom: 20px;
    height: 45px;
    width: 90% !important;
    font-size: 1rem;
  }
}