.all-container {
  height: 100%;
  margin: 0 2% 86px 2%;
  overflow: auto;
}

@media screen and (max-width: 991px) {
  .all-container {
    margin: 0 2% calc(36px + 5vw) 2%;
  }
}

@media screen and (max-width: 768px) {
  .all-container {
    margin: 0 2% 60px 2%;
  }
}

@media screen and (max-width: 576px) {
  .all-container {
    margin: 0 2% 55px 2%;
  }
}
