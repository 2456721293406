.page-title {
  padding-left: 2%;
  margin: 0 0 15px 0;
  width: fit-content;
  padding-right: 50px;
  overflow: hidden;
  height: 36px;
}

.user-btn {
  outline: none;
  position: absolute;
  right: 2%;
  top: 3%;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  filter: drop-shadow(0 0 3px #3051ff);
  cursor: pointer;
}

.user-btn div {
  width: 50px !important;
  height: 50px !important;
}

@media (max-width: 600px) {
  .page-title {
    font-size: 1.3rem !important;
    height: 26px !important;
  }
  .user-btn {
    right: 4%;
    top: 4%;
  }
  .user-btn div {
    width: 40px !important;
    height: 40px !important;
  }
}