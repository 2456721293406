.create-team-modal .dialog-paper {
	background: #140052 !important;
	border-radius: 25px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	flex-direction: column !important;
	text-align: center !important;
	width: 400px !important;
}

.team-name {
	margin: 0;
}

/* .team-name::placeholder {} */

.team-primary-btn.modal-input {
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
}

.modal-input {
	font-weight: bold !important;
}

.team-error {
	font-size: 0.8rem;
	color: red;
}

.create-btn-div {
	display: flex;
	justify-content: center;
	align-items: center;
}