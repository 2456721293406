@import url(https://fonts.googleapis.com/css?family=Montserrat:400;500;600;700;800;900);
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080020;
  color: #ffffff;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

*::-webkit-scrollbar {
  width: 0px;
}

.grecaptcha-badge {
  display: none !important;
}

.modal-input {
  outline: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  border: 1px solid transparent;
  padding: 3% 5%;
  margin-bottom: 0.3rem;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  background-color: #1c006f;
  color: #ffffff;
}

.modal-input::placeholder {
  color: #3000c0;
  font-weight: 400 !important;
}