textarea {
  resize: none;
}

.submit-btn {
  width: 100% !important;
  margin: 4px 4px 0 0;
}

/* Mui Overrides */
textarea.MuiInputBase-input {
  border-radius: 0px !important;
}
