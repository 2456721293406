@import url(https://fonts.googleapis.com/css?family=Roboto:500);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400;500;600;700;800;900);
.devsoc21 {
  background: linear-gradient(91.52deg, #3051ff 0.45%, #25d8ff 107.04%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0;
  padding-left: 2%;
  padding-top: 2%;
  font-size: 1.75rem;
}

.ds-landing {
  padding-top: 5% !important;
  padding-left: 5% !important;
  font-size: 5rem;
}

.landing-page {
  height: 100vh;
  margin: 0;
  width: 100vw;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Google Button */
.google-link {
  position: absolute;
  right: 15%;
  text-align: center;
  bottom: 30%;
}

.sign-txt {
  font-weight: 600;
  font-size: 2.6rem;
}

.google-btn {
  width: 200px;
  height: 45px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin: 30px auto;
}

.google-icon-wrapper {
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 2px;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.google-icon {
  height: 100%;
  background-color: #4285f4;
}

.google-btn .btn-text {
  float: right;
  margin: 15px 15px 0 0;
  color: white;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: "Roboto" !important;
}

.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}

.google-btn:active,
.google-btn:active .google-icon {
  background: #1669f2;
}

@media (min-width: 768px) {
  .google-btn {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@media screen and (max-width: 768px) {
  .ds-landing {
    font-size: 4rem;
    width: 100%;
    text-align: center;
    padding: 5% 0 0 0 !important;
  }
  .google-link {
    position: static;
    position: initial;
    margin: auto;
    margin-top: 45vh;
  }
  .devsoc21 {
    padding-top: 4%;
  }
}

@media screen and (max-width: 400px) {
  .ds-landing {
    font-size: 3rem;
    width: 100%;
    text-align: center;
    padding: 25% 0 0 0 !important;
  }
  .sign-txt {
    font-size: 1.3rem;
  }
}

.create-team-modal .dialog-paper {
	background: #140052 !important;
	border-radius: 25px !important;
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-justify-content: center !important;
	        justify-content: center !important;
	-webkit-align-items: center !important;
	        align-items: center !important;
	-webkit-flex-direction: column !important;
	        flex-direction: column !important;
	text-align: center !important;
	width: 400px !important;
}

.team-name {
	margin: 0;
}

/* .team-name::placeholder {} */

.team-primary-btn.modal-input {
	margin-bottom: 30px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 1.2rem;
}

.modal-input {
	font-weight: bold !important;
}

.team-error {
	font-size: 0.8rem;
	color: red;
}

.create-btn-div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.create-team-modal.invite-modal .MuiDialogContent-root {
	width: 80%;
}

.snackbar .MuiIconButton-root {
	background-color: transparent;
}

.snackbar .MuiIconButton-root:hover {
	background-color: rgba(0, 0, 0, 0.103);
}

.snackbar .MuiSvgIcon-root {
	fill: white !important;
}
.to-be-removed-div {
	margin-top: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 0 5%;
}

.create-team-modal.remove-modal .remove-paper {
	width: 70% !important;
	overflow: hidden;
}

.team-member-rem {
	width: 100% !important;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	text-align: left;
	margin: 10px 0;
}

.member-rem {
	font-weight: normal !important;
	margin: 5px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.remove-icon-btn {
	width: 50px !important;
	height: 50px !important;
}

.remove-icon-btn .MuiSvgIcon-root {
	fill: white !important;
}

@media screen and (max-width: 600px) {
	.member-rem {
		font-size: 1rem;
	}
	.create-team-modal.remove-modal .MuiPaper-root {
		width: 90% !important;
	}
}
.team-container {
  width: 100%;
  height: calc(100% - 10px);
  margin: 3px 0 7px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-height: 500px) {
  .team-container {
    height: auto;
  }
}

.team-div {
  border: 2px solid #3051ff;
  border-radius: 50px;
  width: 90%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 3%;
  text-align: center;
}

.team-joined-div {
  width: 100%;
  height: 100%;
  padding: 0 3%;
}

.team-members-head-div .gradient-head {
  margin: 0;
}

.remove-btn {
  color: rgb(148, 148, 148);
}

.remove-btn:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.team-primary-btn {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  border-radius: 15px;
  background: linear-gradient(91.89deg, #6f57ff -5.11%, #25d8ff 152.73%);
  color: white;
  height: 55px;
  width: 300px;
  font-weight: 600;
  font-size: 1.2rem;
}

.team-primary-btn:hover {
  -webkit-filter: drop-shadow(0 0 10px #6f57ff);
          filter: drop-shadow(0 0 10px #6f57ff);
}

.team-secondary-btn {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  border-radius: 15px;
  border: 2px solid #3051ff;
  background: transparent;
  color: white;
  height: 55px;
  width: 300px;
  font-weight: 600;
  font-size: 1.2rem;
}

.team-secondary-btn:hover {
  -webkit-filter: drop-shadow(0 0 10px #3051ff);
          filter: drop-shadow(0 0 10px #3051ff);
}

@media screen and (max-width: 600px) {
  .team-primary-btn, .team-secondary-btn {
    width: 200px;
  }
  .remove-btn {
    font-size: 0.8rem;
  }
}

.gradient-head {
  background: linear-gradient(98.49deg, #6f57ff, #25d8ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0;
}

.team-name {
  font-size: 1.7rem;
  margin-top: 0 !important;
}

.team-status {
  font-size: 1.5rem;
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-status.team-member {
  margin-bottom: 5px;
}

.team-action-div {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 40px;
}

@media screen and (max-width: 600px) {
  .member-name-grid {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
}

@media screen and (max-width: 490px) {
  .team-name {
    font-size: 1.5rem;
    margin-top: 0 !important;
  }
  .gradient-head {
    font-size: 1.5rem;
  }
  .team-status {
    font-size: 1.2rem;
  }
  .team-action-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .team-action-div .team-primary-btn {
    margin-bottom: 10px;
    font-size: 1rem;
    width: 90% !important;
    height: 45px;
  }
  .team-action-div .team-secondary-btn {
    margin-bottom: 20px;
    height: 45px;
    width: 90% !important;
    font-size: 1rem;
  }
}
.app-nav {
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  background-color: #080020;
  z-index: 100;
}

.nav-tab-div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.nav-tabs-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 12px 12px 0 0;
  /* box-shadow: 0 -1px 2px #25d8ff; */
}

.nav-tab {
  display: inline-block !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 25px !important;
  margin-right: 25px !important;
  color: #ffffff !important;
}

.nav-tab svg {
  font-size: 50px !important;
}

.nav-tab:hover {
  -webkit-filter: drop-shadow(0 0 1px #6f57ff);
          filter: drop-shadow(0 0 1px #6f57ff);
}

.nav-tab.active:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: linear-gradient(135deg, #6f57ff 0%, #25d8ff 100%);
  border-radius: 50%;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .nav-tab svg {
    font-size: 5vw !important;
  }
}

@media screen and (max-width: 768px) {
  .nav-tab svg {
    font-size: 36px !important;
  }
  .nav-tab:hover {
    -webkit-filter: none;
            filter: none;
  }
  .nav-tab.active:after {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .nav-tab {
    margin-bottom: 5px !important;
  }
  .nav-tab-div {
    width: 100% !important;
  }
  .nav-tabs-container {
    width: 100% !important;
    border-radius: 0;
    box-shadow: 0 -1px 0 #25d8ff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
}

.dashset {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

.dashhack {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.clock {
  font-size: 4.8rem;
  font-weight: 600;
  letter-spacing: 0em;
  /* background: linear-gradient(94.73deg, #6f57ff 0%, #25d8ff 114.11%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #6f57ff;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.clock-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 10px;
}

.clock-label {
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .dashset {
    font-size: 1.8rem;
  }
  .clock {
    font-size: 3rem;
  }
}

.loading-screen {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-size: cover;
}

.loading-screen img {
  width: 200px;
  -webkit-animation: spin 2s ease-in-out infinite forwards;
          animation: spin 2s ease-in-out infinite forwards;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  70% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  70% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .loading-screen img {
    width: 160px;
  }
}

.page-title {
  padding-left: 2%;
  margin: 0 0 15px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 50px;
  overflow: hidden;
  height: 36px;
}

.user-btn {
  outline: none;
  position: absolute;
  right: 2%;
  top: 3%;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 3px #3051ff);
          filter: drop-shadow(0 0 3px #3051ff);
  cursor: pointer;
}

.user-btn div {
  width: 50px !important;
  height: 50px !important;
}

@media (max-width: 600px) {
  .page-title {
    font-size: 1.3rem !important;
    height: 26px !important;
  }
  .user-btn {
    right: 4%;
    top: 4%;
  }
  .user-btn div {
    width: 40px !important;
    height: 40px !important;
  }
}
textarea {
  resize: none;
}

.submit-btn {
  width: 100% !important;
  margin: 4px 4px 0 0;
}

/* Mui Overrides */
textarea.MuiInputBase-input {
  border-radius: 0px !important;
}

.user_profile_picture {
  width: 6rem !important;
  height: 6rem !important;
}

.social {
  margin: 20px 0 10px 0;
  display: -webkit-flex;
  display: flex;
}

.social a {
  text-decoration: none;
  color: #fff !important;
}

.social svg {
  font-size: 2rem;
  margin: 0 3px;
}

@media screen and (max-width: 600px) {
  .profile-btn {
    width: 160px;
    margin: 5px;
    height: 40px;
  }
  .user_profile_picture {
    margin: auto;
  }
  .profile-data {
    text-align: center;
    font-size: 13px;
  }
  .social {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.sub-side {
  margin: 0 0 20px -8px !important;
}

@media (max-width: 959px) {
  .sub-side {
    margin: 0 0 20px 0 !important;
  }
}

.all-container {
  height: 100%;
  margin: 0 2% 86px 2%;
  overflow: auto;
}

@media screen and (max-width: 991px) {
  .all-container {
    margin: 0 2% calc(36px + 5vw) 2%;
  }
}

@media screen and (max-width: 768px) {
  .all-container {
    margin: 0 2% 60px 2%;
  }
}

@media screen and (max-width: 576px) {
  .all-container {
    margin: 0 2% 55px 2%;
  }
}

.final-left-box {
	padding-left: 3% !important;
}

.file-gradient-head {
	background: linear-gradient(98.49deg, #6f57ff, #25d8ff);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
	.final-left-box {
		padding-left: 8px !important;
	}
}
.team-card {
	width: 100%;
	border-radius: 15px;
	border: 3px solid #3051FF;
	margin: 40px 0;
	box-sizing: border-box;
	cursor: pointer;
	transition: 0.1s all ease-in-out;
}

.team-card:hover {
	background-color: #140052;
}

.team-card h2 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.8rem;
}

.card-team-name {
	background: linear-gradient(94.73deg, #6f57ff 0%, #25d8ff 114.11%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 900 !important;
}

.team-card h4 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	/* color: rgba(255, 255, 255, 0.4); */
	font-weight: 200 !important;
}

.team-card-divider {
	width: 7px;
	height: 100%;
	background-color: #3051FF;
	border-radius: 10px;
	margin-right: 20px;
}

.card-vertical-divider {
	width: 90%;
	height: 2px;
	background-color: #3051FF;
	border-radius: 20px;
	/* margin-bottom: 10px; */
}

.card-count-div {
	padding: 20px 30px !important;
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-align-items: center !important;
	        align-items: center !important;
	-webkit-justify-content: flex-end !important;
	        justify-content: flex-end !important;
}

@media screen and (max-width: 599px) {
	.card-count-div {
		padding: 20px 20px !important;
		padding-top: 0 !important;
		-webkit-justify-content: flex-end !important;
		        justify-content: flex-end !important;
	}
}

.card-members-div {
	width: 100%;
	padding: 0 30px;
	box-sizing: border-box;
	padding-bottom: 10px;
}

.member-container-div {
	padding-left: 30px !important;
}

.card-member-name {
	background: linear-gradient(94.73deg, #6f57ff 0%, #25d8ff 114.11%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	/* font-weight: bold; */
}

@media screen and (max-width: 600px) {
	.member-container-div {
		padding-left: 0 !important;
	}
}
.all-teams-div {
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-flex-direction: column !important;
	        flex-direction: column !important;
	/* justify-content: center; */
	-webkit-align-items: center !important;
	        align-items: center !important;
}

.search-container {
	width: 40%;
	margin-bottom: 10%;
}

.search-box {
	width: 100%;
}

.search-box .MuiOutlinedInput-notchedOutline {
	border-width: 3px !important;
	border-color: #3051FF !important;
	border-radius: 15px !important;
}

.search-box .MuiFormLabel-root {
	font-weight: bold !important;
}

.search-box .MuiFormLabel-root.Mui-focused {
	color: #3051FF !important;
}

.all-teams-list {
	width: 60%;
}

@media screen and (max-width: 960px) {
	.search-container {
		width: 70%;
	}
	.all-teams-list {
		width: 90%;
	}
}
.team-details-div {
	height: calc(100vh - 120px);
}

@media screen and (max-width: 600px) {
	.team-details {
		background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/static/media/teamDetails.0a533401.png);
		background-position: center left;
		/* background-size: cover; */
	}
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080020;
  color: #ffffff;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

*::-webkit-scrollbar {
  width: 0px;
}

.grecaptcha-badge {
  display: none !important;
}

.modal-input {
  outline: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  border: 1px solid transparent;
  padding: 3% 5%;
  margin-bottom: 0.3rem;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  background-color: #1c006f;
  color: #ffffff;
}

.modal-input::-webkit-input-placeholder {
  color: #3000c0;
  font-weight: 400 !important;
}

.modal-input:-ms-input-placeholder {
  color: #3000c0;
  font-weight: 400 !important;
}

.modal-input::placeholder {
  color: #3000c0;
  font-weight: 400 !important;
}
