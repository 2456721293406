.user_profile_picture {
  width: 6rem !important;
  height: 6rem !important;
}

.social {
  margin: 20px 0 10px 0;
  display: flex;
}

.social a {
  text-decoration: none;
  color: #fff !important;
}

.social svg {
  font-size: 2rem;
  margin: 0 3px;
}

@media screen and (max-width: 600px) {
  .profile-btn {
    width: 160px;
    margin: 5px;
    height: 40px;
  }
  .user_profile_picture {
    margin: auto;
  }
  .profile-data {
    text-align: center;
    font-size: 13px;
  }
  .social {
    display: flex;
    justify-content: center;
  }
}
