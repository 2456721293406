.app-nav {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #080020;
  z-index: 100;
}

.nav-tab-div {
  width: fit-content;
  margin: auto;
}

.nav-tabs-container {
  width: fit-content;
  border-radius: 12px 12px 0 0;
  /* box-shadow: 0 -1px 2px #25d8ff; */
}

.nav-tab {
  display: inline-block !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 25px !important;
  margin-right: 25px !important;
  color: #ffffff !important;
}

.nav-tab svg {
  font-size: 50px !important;
}

.nav-tab:hover {
  filter: drop-shadow(0 0 1px #6f57ff);
}

.nav-tab.active:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: linear-gradient(135deg, #6f57ff 0%, #25d8ff 100%);
  border-radius: 50%;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .nav-tab svg {
    font-size: 5vw !important;
  }
}

@media screen and (max-width: 768px) {
  .nav-tab svg {
    font-size: 36px !important;
  }
  .nav-tab:hover {
    filter: none;
  }
  .nav-tab.active:after {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .nav-tab {
    margin-bottom: 5px !important;
  }
  .nav-tab-div {
    width: 100% !important;
  }
  .nav-tabs-container {
    width: 100% !important;
    border-radius: 0;
    box-shadow: 0 -1px 0 #25d8ff;
    display: flex;
    justify-content: space-evenly;
  }
}
