.dashset {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

.dashhack {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.clock {
  font-size: 4.8rem;
  font-weight: 600;
  letter-spacing: 0em;
  /* background: linear-gradient(94.73deg, #6f57ff 0%, #25d8ff 114.11%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #6f57ff;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.clock-label {
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .dashset {
    font-size: 1.8rem;
  }
  .clock {
    font-size: 3rem;
  }
}
